/* About.module.css */

.container {
    display: flex;
    flex-direction: row; /* Default to row for larger screens */
    align-items: center; /* Vertically center the content */
    justify-content: start; /* Aligns content to the start of the container */
    height: auto;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-bottom: 3%;
    background-color: rgba(255, 255, 255, 0.448);
    border-radius: 10px;
}
.text{
    font-size: 27px;
    font-family: futura;
    color: #530940;
    margin-top: 3%;
    margin-bottom: 3%;

}
.header{
    font-size: 40px;
    font-family: futura;
    color: #530940;
    padding-top: none;
   
    margin-bottom: 3%;
    text-align: center;

}

.leftSide, .rightSide {
    flex: 1; /* Each side takes up half of the container */
    padding: 20px;
}

.leftSide img {
    width: 100%; /* Full width of the left side */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
}

.rightSide {
    text-align: left;
    
    
}

.imageResponsive {
    width: 100%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Stack the sides vertically on smaller screens */
    }
    .header{
        font-size: 20px;
    }
    .text{
        font-size: 12px;
        text-align: center;

    }

    .imageResponsive {
        height: 400px; /* Fix the height on small devices if needed */
    }
}
