.navbar {
  background-color: #fee5fa;
  color: white;
  padding: 10px 20px;
  font-family: futura;
  font-weight: 100;
}

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navLogo {
  font-size: 42px;
  color: white;
  text-decoration: none;
}

.navLinks {
  display: flex;  /* Always display flex on desktop */
}

.navItem {
  color: #530940;
  text-decoration: none;
  margin-left: 20px;
  font-size: 35px;
  font-weight: 50;
  padding: 8px 12px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.navItem:hover {
  background-color: #C71585;
}

.menuButton {
  display: none;
  font-size: 30px;
  background: none;
  border: none;
  color: #530940;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .navbar {
    padding-bottom: 140px;
    justify-content: center; /* Center content horizontally */
  }

  .menuButton{
    position: absolute;
    right:0%;
    top:5%;
    margin-right: 20px;
  }

  .navLogo{
    position: absolute;
    right:20%;
    top:1%;

    
  }
  
  .navLinks {
    display: none; /* Hide nav links on mobile by default */
    flex-direction: column; /* Stack links vertically */
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fee5fa;
    width: 100%;
    transition: all 0.3s ease; /* Smooth transition for menu */
  }

  .navLinks.active {
    display: flex; /* Show nav links when menu is open */
  }

  .menuButton {
    display: block; /* Show menu button on mobile */
  }

  .navItem {
    font-size: 20px; /* Reduced font size for mobile */
    margin-left: 0; /* Remove left margin */
    padding: 10px 15px; /* Adjust padding for mobile */
  }
}
