.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start; /* Aligns content to the top of the container */
    height: auto; 
   
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
    margin-top: 5%;
    padding-bottom: 3%;
    background-color: rgba(255, 255, 255, 0.448);
    border-radius: 10px;
  }

  .Title {
    font-size: 35px; /* Default font size for larger screens */
    font-family: futura;
    color: #530940;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .imageResponsive {
    width: 50%; /* Responsive width */
    height: auto; /* Maintain aspect ratio */
  }

  @media (max-width: 768px) {
    .Title {
      font-size: 20px; /* Smaller font size for tablets and large phones */
    }
   

    .imageResponsive {
        height: 100%;
        width: 80%; /* Fix the height on small devices if needed */
    }
}
