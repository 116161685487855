body, html {
  margin: 0;
  /* Use the Arial font */
  background-color: #fee5fa; /* A very light pink background for the whole page */
  zoom:80%;
  
}


@media (max-width: 480px) {
.body, html {
zoom:100%;
  
}
}