.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Aligns content to the top of the container */
  height: 50vh;  /* Use the full height of the viewport */
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  margin-top: 5%;
  background-color: rgba(255, 255, 255, 0.448);
  border-radius: 10px;
}

.Welcome {
  font-size: 50px;
  font-family: futura;
  color: #530940;
  margin-bottom: 3%;
}

.linkButton {
  background-color: #FF69B4;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 25px;
  font-family: futura;
  text-decoration: none;
  margin-top: 20px; /* Add some space between the text and the button */
}

/* Media Queries for mobile devices */

@media (max-width: 480px) {
  .container {
    height: auto; /* Make height automatic to accommodate smaller screens */
    margin-left: 5%; /* Reduce lateral margins for smaller screens */
    margin-right: 5%;
    padding: 20px; /* Add padding to keep content from touching the edges */
  }

  .Welcome {
    font-size: 24px; /* Even smaller font size for very small devices */
  }

  .linkButton {
    font-size: 16px; /* Further reduce the font size of the button */
  }
}
