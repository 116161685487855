.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start; /* Aligns content to the top of the container */
  height: auto; 
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  margin-top: 5%;
  padding-bottom: 3%;
  background-color: rgba(255, 255, 255, 0.448);
  border-radius: 10px;
}

.Title {
  font-size: 35px; /* Default font size for larger screens */
  font-family: futura;
  color: #530940;
  margin-top: 3%;
  margin-bottom: 3%;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2%;
}

.photo img {
  width: 100%;
  height: 200px; /* Fixed height for uniformity */
  object-fit: cover; /* Ensures images cover the area without distortion */
  cursor: pointer; /* Indicates clickable images */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure modal is on top of other content */
}

.modalContent {
  max-width: 80%;
  max-height: 80%;
}

.close {
  position: absolute;
  top: 20px;
  right: 40px;
  font-size: 40px;
  color: white;
  cursor: pointer;
}

/* Navigation buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 20px;
  user-select: none;
  -webkit-user-select: none;
}

.prev {
  left: 0;
  margin-left: 20%;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  font-size: 30px;
}

.next {
  right: 0;
  margin-right: 20%;
  width: 100px;
  height: 100px;
  border-radius: 100px;
  font-size: 30px;
}

@media (max-width: 480px) {

  .container{
    height:95vh;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .prev{
    margin-left: .75%;
    width: 50px;
    height: 50px;
    font-size: 15px;

  }
  .next{
    margin-right: .75%;
    width: 50px;
    height: 50px;
    font-size: 15px;

  }
  
  .photo img {
    width: 80%;
    height: 100px; /* Fixed height for uniformity */
  }

  .Title {
    font-size: 20px;
  }
}
