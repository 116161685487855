.formContainer {
    background-color: #FFC0CB;
    padding: 20px;
    border-radius: 8px;
    color: #C71585;
  }
  
  .submitButton {
    background-color: #FF69B4;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Aligns content to the top of the container */
    height: 150vh;  /* Use the full height of the viewport */
    text-align: center;
    font-family: futura;
    color: #530940;
    
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10%;
    margin-top: 2%;
    background-color: rgba(255, 255, 255, 0.448);
    border-radius: 10px;
  }
  
  .Title {
    font-size: 35px; /* Default font size for larger screens */
    font-family: futura;
    color: #530940;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  
  /* Media Queries for mobile devices */
  @media (max-width: 768px) {
    .Title {
      font-size: 28px; /* Smaller font size for tablets and large phones */
    }
  }
  
  @media (max-width: 480px) {
    .Title {
      font-size: 24px; /* Even smaller font size for small screens */
    }

    .container{
      margin-top: 30px;
      height:93vh;
    }
  }
  