/* global.css */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  *, *::before, *::after {
    box-sizing: inherit;
  }
  
  /* Responsive text-sizing and margins */
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: 100%;
  }
  
  @media (max-width: 768px) {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 22px;
    }
    .container {
        width:2px;

    }
    /* Adjust as necessary */
  }
  
  @media (max-width: 480px) {
    h1 {
      font-size: 20px;
    }
    h2 {
      font-size: 18px;
    }
  }
  